import { configureStore } from '@reduxjs/toolkit';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from './localStorage';

const persistedState = loadStateFromLocalStorage();

const store = configureStore({
  reducer: {

  },
  preloadedState: persistedState,
});

window.addEventListener('beforeunload', () => {
  saveStateToLocalStorage(store.getState());
});


export default store;