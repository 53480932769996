import React,{lazy, Suspense} from 'react';
import { BrowserRouter,Routes ,Route, Navigate } from 'react-router-dom';
import Loader from '../view/elements/Loader.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Component with Lazy Loading

const FinancierMRP = lazy(() => import('../view/ph-calculator/FinancierMRP.js'));
const NotFound = lazy(() => import("../view/not-found/NotFound"));
const showHeader = () => {
  return false;
};

const MainRoute=()=>{
    return(
        <BrowserRouter>
        <div>
          {/* <Navigation /> */}
          <ToastContainer />
          <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/" element={<Navigate to="/dif-ph-calculator" replace />} />
            <Route path= "*" element={<NotFound showHeader = {showHeader} />} />
            <Route path={`/dif-ph-calculator`} element={<FinancierMRP/>}/>           
          </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    )
}

export default MainRoute;

