import React,{useEffect} from 'react';

import './App.scss';

import 'react-toastify/dist/ReactToastify.css';
import MainRoute from './routes/routes';
import "react-tippy/dist/tippy.css";

const App = () => {
  // const dispatch=useDispatch()
  // useEffect(() => {
  //   dispatch();
  // }, [dispatch])
    
    return (
     <MainRoute/>
    );
}

export default App;

